import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 56px;

  flex-grow: 1;

  justify-content: end;
`;
