import React, { useEffect, useState } from 'react';
import { getRealm, keycloakAuthService } from 'samolet-keycloak-auth';

import { initAuth } from 'samolet-oauth2';
import { IS_SAAS } from 'app/constants/common';
import { FullContainerSpinner } from 'shared/ui/full-container-spinner';

type AuthProviderType = {
  children: React.ReactNode;
};

export const AuthProvider: React.FC<AuthProviderType> = ({ children }) => {
  const [loading, setLoading] = useState(true);

  const authorize = async () => {
    try {
      await initAuth({
        clientId: __SAMOLET_OAUTH2_PKCE_CLIENT_ID__,
        allowAnonymous: true,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const keycloakAuthorize = async () => {
    try {
      await keycloakAuthService.initKeycloak(
        {
          url:
            process.env.NODE_ENV === 'development'
              ? 'https://keycloak-saas.yc-stage.samolet10d.ru'
              : __KEYCLOAK_INIT_URL__,
          realm: process.env.NODE_ENV === 'development' ? 'alpha' : getRealm(),
          clientId: 'info-frontend',
        },
        true,
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    IS_SAAS ? keycloakAuthorize() : authorize();
  }, []);

  if (loading) return <FullContainerSpinner />;

  return <>{children}</>;
};
