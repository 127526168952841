import { Header, HeaderProps } from '@10d/tend-ui-header';
import { Notifications } from '@10d/tend-ui-notifications';
import { useCallback, useMemo } from 'react';
import { goToAuth, goToRegister } from 'samolet-oauth2';
import { useProfileDropdownFilterHandler } from 'app/ui/main-layout/hooks';
import { Profile as ProfileModel } from 'entities/profile/model';
import { IS_SAAS } from 'app/constants/common';
import { keycloakAuthService } from 'samolet-keycloak-auth';
import { CURRENT_SERVICE } from '../../MainLayout.consts';
import * as Styled from './Header.styled';
import { jwtDecode } from 'jwt-decode';
import { ServicesHeader } from '../services-header';

export type DecodedToken = {
  exp: number;
  iat: number;
  auth_time: number;
  jti: string;
  iss: string;
  aud: string[];
  sub: string;
  typ: string;
  azp: string;
  nonce: string;
  session_state: string;
  acr: string;
  'allowed-origins': string[];
  realm_access: RealmAccess;
  resource_access: ResourceAccess;
  scope: string;
  sid: string;
  email_verified: boolean;
  name: string;
  preferred_username: string;
  given_name: string;
  family_name: string;
  email: string;
};

type RealmAccess = {
  roles: string[];
};

type ResourceAccess = {
  account: RealmAccess;
};

export const MainLayoutHeader = ({ profile }: { profile?: ProfileModel }) => {
  const dropdownHandlers = useProfileDropdownFilterHandler(profile);
  const userProfile = useMemo(() => {
    if (IS_SAAS) {
      const token = keycloakAuthService.getToken();
      const decodedToken: DecodedToken | null = token ? jwtDecode(token) : null;

      if (!decodedToken) {
        return undefined;
      }

      return {
        email: decodedToken.email,
        username: decodedToken.preferred_username,
        firstName: decodedToken.given_name,
        lastName: decodedToken.family_name,
      };
    } else {
      return profile;
    }
  }, [profile]);

  const handleSignIn = useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    if (!IS_SAAS) {
      goToAuth({ clientId: __SAMOLET_OAUTH2_PKCE_CLIENT_ID__ });
    }
  }, []);

  const handleSignUp = useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    if (!IS_SAAS) {
      goToRegister();
    }
  }, []);

  const headerProfile = useMemo(() => ({ items: dropdownHandlers }), [dropdownHandlers]);

  const headerSlots: HeaderProps['slots'] = useMemo(
    () => ({
      notifications:
        // TODO: Remove container after update tend-ui-notifications version
        profile && (
          <Styled.NotificationsContainer>
            <Notifications />
          </Styled.NotificationsContainer>
        ),
    }),
    [profile],
  );

  const isAuthenticated = IS_SAAS || !!profile;

  const authorization = useMemo(
    () => ({ onSignin: handleSignIn, onSignup: handleSignUp }),
    [handleSignIn, handleSignUp],
  );

  const handleLogout = useCallback(async () => {
    console.log('Logout');
    await keycloakAuthService.logout();
  }, []);

  console.log(IS_SAAS ? handleLogout : undefined);

  return IS_SAAS ? (
    <ServicesHeader productName={CURRENT_SERVICE} />
  ) : (
    <Header
      app={CURRENT_SERVICE}
      stand={process.env.STAND_ENVIRONMENT}
      user={userProfile}
      authenticated={isAuthenticated}
      profile={headerProfile}
      slots={headerSlots}
      authorization={authorization}
      onLogout={handleLogout}
    />
  );
};
