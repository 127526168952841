import { Button } from '@10d/tend-ui/primitives/Button';
import * as Styled from './ErrorPage.styled';

type ErrorPageProps = {
  errorCaption?: string;
  title?: string;
};

const handleReload = () => window.location.reload();

export const ErrorPage = ({
  title = 'Ошибка системы',
  errorCaption = 'Мы уже устраняем проблему.',
}: ErrorPageProps) => (
  <Styled.Wrapper>
    <Styled.TextContainer>
      <Styled.Title>{title}</Styled.Title>
      <Styled.Caption data-testid='error-caption'>{errorCaption}</Styled.Caption>
      <Button size='large' variant='primary' onClick={handleReload}>
        Обновить
      </Button>
    </Styled.TextContainer>
  </Styled.Wrapper>
);
