import styled from 'styled-components';
import { Main, Root } from '@10d/tend-ui/widgets/Layout';

export const LayoutRoot = styled(Root)`
  background-color: ${({ theme }) => `${theme.colors.gray0}`};
`;

export const MainRoot = styled(Main.Root)`
  padding: 0;

  background-color: ${({ theme }) => `${theme.colors.gray0}`};
`;
