import styled, { css } from 'styled-components';
import { media } from 'shared/utils/media';
import { BackgroundColors } from 'shared/ui/types';
import { theme } from 'app/theme';

export const Container = styled.div<{
  $backgroundColor?: BackgroundColors;
}>`
  width: 100%;

  background-color: ${({ $backgroundColor }) =>
    $backgroundColor
      ? theme.backgroundColor[$backgroundColor]
      : theme.backgroundColor.main};
`;

const BodyFullWidth = css`
  width: 100%;
`;

const BodyPerBreakpoint = css`
  width: 100%;
  min-height: calc(100vh - 53px);
  margin: 0 auto;

  ${media({ size: 'm' }, { width: '1024px' })}
  ${media({ size: 'l' }, { width: '1440px' })}
`;

export const Body = styled.div<{
  $withBreakpoints: boolean;
}>`
  position: relative;

  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  padding: 24px;

  ${({ $withBreakpoints }) => ($withBreakpoints ? BodyPerBreakpoint : BodyFullWidth)}
`;
