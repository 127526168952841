import styled from 'styled-components';
import { Spinner as UiSpinner } from '@10d/tend-ui/primitives/Spinner';

export const Container = styled.div`
  position: fixed;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;
  inset: 0;
`;

export const Spinner = styled(UiSpinner)`
  color: ${({ theme }) => theme.colors.blue600};
`;
