import { CSSObject, css } from 'styled-components';
import { theme } from 'app/theme';

export type Breakpoint = 'l' | 'm' | 's';

type MediaParams = {
  size: Breakpoint;
  minmax?: 'min' | 'max';
  addendum?: number;
};

export const media = (
  { minmax = 'min', size, addendum = 0 }: MediaParams,
  styles: CSSObject,
) => {
  const resolution = theme.breakpoints[size];

  return css`
    /* stylelint-disable-next-line */
    @media (${minmax}-width: calc(${resolution}px + ${addendum}px)) {
      ${css(styles)}
    }
  `;
};
