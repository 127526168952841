import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;

  max-width: 768px;
  height: 100vh;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
`;

export const TextContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;

  flex-direction: column;
`;

export const Title = styled.h3`
  margin: 0;

  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
`;

export const Caption = styled.p`
  margin: 16px 0 32px 0;

  white-space: pre-wrap;

  color: #4a5368;

  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
`;
