import { createGlobalStyle } from 'styled-components';
import './reset.css';
import './fonts.css';

const GlobalStyle = createGlobalStyle`
  html {
    overflow: hidden;

    height: 100%;

    font-family: 'Museo Sans Cyrl', sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: 100%;
  }

  body {
    overflow: auto;
    overflow-x: hidden;

    height: 100%;
    min-height: 100%;
    scroll-behavior: smooth;
  }

  #root {
    height: 100%;
  }
`;

export { GlobalStyle };
