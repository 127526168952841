import { lazy } from 'react';
import { RouteProps, generatePath } from 'react-router-dom';
import { ExtractRouteParams } from 'react-router';

const ProductsPage = lazy(() => import('pages/products'));
const CategoriesPage = lazy(() => import('pages/categories'));
const ArticlePage = lazy(() => import('pages/articles'));

const pathGenerator = <S extends string>(pattern: S) => ({
  pattern,
  path: function (params: ExtractRouteParams<S>) {
    return generatePath(pattern, params);
  },
});

export const paths = {
  products: {
    key: 'products',
    path: '/products',
  },
  categories: {
    key: 'categories',
    ...pathGenerator('/products/:productGuid?/categories'),
  },
  article: {
    key: 'article',
    ...pathGenerator('/articles/:articleGuid?'),
  },
};

export const routing: (RouteProps & { key: string })[] = [
  {
    key: paths.products.key,
    path: paths.products.path,
    exact: true,
    component: ProductsPage,
  },
  {
    key: paths.categories.key,
    path: paths.categories.pattern,
    exact: true,
    component: CategoriesPage,
  },
  {
    key: paths.article.key,
    path: paths.article.pattern,
    exact: true,
    component: ArticlePage,
  },
];

export const sentryPaths = (() =>
  routing.reduceRight((paths: Array<{ path: string }>, route) => {
    if (typeof route.path === 'string') {
      paths.push({ path: route.path });
    }

    return paths;
  }, []))();
