import styled from 'styled-components';
import { Header } from '@10d/tend-ui/widgets/Layout';
import { colors } from '@10d/tend-ui-tokens/samolet/colors';

export const HeaderRoot = styled(Header.Root)`
  transition: transform 0.35s ease;
`;

export const NotificationsContainer = styled.div`
  .tend-ui-toggle-button {
    color: ${colors.gray0};

    &:hover:not(:disabled) {
      color: ${colors.gray0};
      background: #1988fb;
    }

    &:active:not(:disabled) {
      color: ${colors.gray0};
      background: #268ffc;
    }

    .tend-ui-badge-badge {
      transform: translate(-1px, 0);

      border-color: ${({ theme }) => `${theme.colors.blue600}`} !important;
      background-color: ${({ theme }) => `${theme.colors.gray0}`} !important;
    }
  }
`;
