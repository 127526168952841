import React from 'react';

import queryString from 'query-string';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

type RouteProviderType = {
  children: React.ReactNode;
};

const queryParamOptions = {
  searchStringToObject: queryString.parse,
  objectToSearchString: queryString.stringify,
};

export const RouterProvider: React.FC<RouteProviderType> = ({ children }) => (
  <BrowserRouter>
    <QueryParamProvider adapter={ReactRouter5Adapter} options={queryParamOptions}>
      {children}
    </QueryParamProvider>
  </BrowserRouter>
);
