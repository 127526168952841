import { AnalyticsCounters } from '@10d/analytics-counters';
import { setUser, withProfiler } from '@sentry/react';
import { useEffect } from 'react';
import { authStorage } from 'samolet-oauth2';
import { useProfileQuery } from 'entities/profile/api';
import { FullContainerSpinner } from 'shared/ui';
import { Router } from './routing/Router';
import { MainLayout } from './ui';
import { withErrorBoundary } from './ui/error-boundary';

const App = () => {
  const accessToken = authStorage.getJwtAuthParams()?.access;

  const { profile, profileLoading } = useProfileQuery({
    queryParams: { enabled: !!accessToken },
  });

  useEffect(() => {
    if (profile) {
      setUser({
        id: String(profile.id),
        username: profile.username,
        email: profile.email,
      });

      AnalyticsCounters.globalParams({
        user_id: profile.id,
        external_user: profile.role === 'partner' ? 1 : 0,
      });
    }
  }, [profile]);

  if (accessToken && profileLoading) {
    return <FullContainerSpinner />;
  }

  return (
    <MainLayout>
      <Router />
    </MainLayout>
  );
};

export default withProfiler(withErrorBoundary(App));
