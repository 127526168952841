import { ErrorBoundary } from '@sentry/react';
import { ComponentType } from 'react';
import { ErrorPage } from '../error-page';

export const withErrorBoundary = (WrappedComponent: ComponentType) => {
  const WithErrorBoundary = () => (
    <ErrorBoundary fallback={<ErrorPage />}>
      <WrappedComponent />
    </ErrorBoundary>
  );

  WithErrorBoundary.displayName = `WithErrorBoundary(${getDisplayName(
    WrappedComponent,
  )})`;

  return WithErrorBoundary;
};

const getDisplayName = (WrappedComponent: ComponentType) =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component';
