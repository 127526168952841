import { Profile } from '@10d/tend-ui/components/Profile';
import { Box } from '@10d/tend-ui/grid/Box';
import { Logout } from '@10d/tend-ui/icons';
import { Text } from '@10d/tend-ui/typography';
import { Image } from '@10d/tend-ui/ui';
import { Header } from '@10d/tend-ui-header';
import { Stand } from '@10d/tend-ui-header/core/Stand';
import { jwtDecode } from 'jwt-decode';
import { useCallback, useMemo } from 'react';
import { keycloakAuthService } from 'samolet-keycloak-auth';

// import { BurgerMenu } from './components/burger-menu';
import { DecodedToken } from './types';

export type ServicesHeaderProps = {
  menu?: { label: JSX.Element; key: string }[];
  defaultMenuItem?: string[];
  HeaderStyling?: Record<string, string>;
  logo?: JSX.Element;
  productName: string;
};

export const ServicesHeader = ({
  menu,
  defaultMenuItem,
  HeaderStyling,
  logo,
  productName,
}: ServicesHeaderProps) => {
  const token = keycloakAuthService.getToken();
  const decodedToken: DecodedToken | null = token ? jwtDecode(token) : null;

  const handleLogout = useCallback(() => {
    keycloakAuthService.logout();
  }, []);

  const profileMenu = useMemo(
    () => [
      {
        key: 'logout',
        label: (
          <Text>
            <Logout /> Выйти
          </Text>
        ),
        onClick: handleLogout,
      },
    ],
    [handleLogout],
  );

  const formattedProductName = useMemo(
    () =>
      productName
        .split('.')
        .map(v => v[0].toUpperCase() + v.slice(1))
        .join('.'),
    [productName],
  );

  return (
    <Header.Root background='blue600'>
      <Box $display='flex' $gap={4} $alignItems={'center'}>
        {/* <BurgerMenu /> */}
        <Image
          src={'https://cdn.samolet10d.ru/tend-ui/logos/sinfo-gray0.svg'}
          width={20}
          height={20}
        />
        <Header.Logo
          before={logo}
          after={
            process.env.STAND_ENVIRONMENT &&
            process.env.STAND_ENVIRONMENT !== 'prod' && (
              <Stand stand={process.env.STAND_ENVIRONMENT} />
            )
          }
        >
          {formattedProductName}
        </Header.Logo>
        <Box
          $alignItems='center'
          $display='flex'
          $flex='1'
          $gap={8}
          $justifyContent='flex-end'
        >
          <Profile
            description={decodedToken?.email}
            title={`${decodedToken?.given_name} ${decodedToken?.family_name}`}
            items={profileMenu}
          />
        </Box>
      </Box>
      {menu && (
        <Header.Navigation
          items={menu}
          defaultSelectedKeys={defaultMenuItem}
          styling={HeaderStyling}
        />
      )}
    </Header.Root>
  );
};
