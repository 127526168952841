import React from 'react';
import { useProfileQuery } from 'entities/profile/api';
import { MainLayoutHeader } from 'app/ui/main-layout/ui/header';
import * as Styled from './MainLayout.styled';

export const MainLayout: React.FC = ({ children }) => {
  const { profile } = useProfileQuery();

  return (
    <Styled.LayoutRoot profile={profile} stand={process.env.STAND_ENVIRONMENT}>
      <MainLayoutHeader profile={profile} />
      <Styled.MainRoot>{children}</Styled.MainRoot>
    </Styled.LayoutRoot>
  );
};
