import { setAxiosAuthInterceptor } from 'samolet-oauth2';
import axios, { AxiosInstance } from 'axios';
import { setAxiosKeycloakAuthInterceptor } from 'samolet-keycloak-auth';
import { IS_SAAS } from 'app/constants/common';

class RestApiClient {
  client: AxiosInstance;

  constructor() {
    this.client = axios.create();

    this.client.defaults.baseURL = '/api';

    if (IS_SAAS) {
      setAxiosKeycloakAuthInterceptor(this.client);
    } else {
      setAxiosAuthInterceptor(this.client, {
        clientId: __SAMOLET_OAUTH2_PKCE_CLIENT_ID__,
        allowAnonymous: true,
      });
    }
  }
}

export default RestApiClient;
