import * as Sentry from '@sentry/react';
import { matchPath } from 'react-router-dom';
import { sentryPaths } from 'app/routing/routes';

const PROD_SENTRY_DSN =
  'https://65aaa2b8180a4b12b2d23a7d63360b11@sentry.prd01.samoletgroup.ru/110';
const STAGE_SENTRY_DSN =
  'https://c3ccbdac435f4a43a5142c292cc2d816@sentry.prd01.samoletgroup.ru/106';

const isProduction = process.env.STAND_ENVIRONMENT === 'prod';

export const sentryInit = () => {
  if (!process.env.STAND_ENVIRONMENT) return;

  try {
    Sentry.init({
      dsn: isProduction ? PROD_SENTRY_DSN : STAGE_SENTRY_DSN,
      environment: process.env.STAND_ENVIRONMENT,
      release: process.env.COMMIT_TAG || process.env.COMMIT_SHORT_SHA,
      transport: Sentry.makeFetchTransport,
      stackParser: Sentry.defaultStackParser,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(
            history,
            sentryPaths,
            matchPath,
          ),
        }),
        new Sentry.ModuleMetadata(),
        new Sentry.Replay({
          maskAllText: false,
          blockAllMedia: false,
          minReplayDuration: 1 * 60 * 1000,
          maxReplayDuration: 3 * 60 * 1000,
        }),
      ],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  } catch (error) {
    console.log('Failed to initialize Sentry with error: ', error);
  }
};
