import { ProfileMenuItem } from '@10d/tend-ui/components';
import { useCallback } from 'react';
import { logout } from 'samolet-oauth2';
import { Profile } from 'entities/profile/model';

export const useProfileDropdownFilterHandler = (profile?: Profile) =>
  useCallback(
    (internalItems: ProfileMenuItem[]) =>
      (profile?.role === 'employee'
        ? internalItems
        : internalItems.filter((item) => item && item.key !== 'profile-menu-item-profile')
      ).map((item) =>
        item?.key === 'profile-menu-item-logout'
          ? { ...item, onClick: () => logout() }
          : item,
      ),
    [profile],
  );
