import React, { Suspense, useEffect } from 'react';

import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AnalyticsCounters } from '@10d/analytics-counters';
import { paths, routing } from './routes';

export const Router: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (!pathname.includes(paths.article.key)) {
      AnalyticsCounters.sendPageView();
    }
  }, [pathname]);

  return (
    <Suspense fallback={null}>
      <Switch>
        {routing.map((route) => (
          <Route
            key={route.key}
            exact={route.exact}
            path={route.path}
            component={route.component}
          />
        ))}
        <Route>
          <Redirect to='/products' />
        </Route>
      </Switch>
    </Suspense>
  );
};
