import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CancelToken,
  Method,
} from 'axios';
import { Toast } from '@10d/tend-ui/primitives/Toast';
import queryString from 'query-string';
import * as Sentry from '@sentry/react';

type AxiosOptions = Omit<AxiosRequestConfig, 'url' | 'method'>;
type GenericParams = Record<string, unknown>;
type GenericData = Record<string, unknown>;

export const provideApiMethods = (axiosInstance: AxiosInstance) => {
  const request = async <T>(
    method: Method,
    url: string,
    options?: AxiosOptions,
    hideMessage?: boolean,
  ) => {
    try {
      const response: AxiosResponse<T> = await axiosInstance.request({
        method,
        url,
        ...options,
      });

      return response.data;
    } catch (error) {
      const err = error as AxiosError;

      if (!hideMessage) requestErrorMessage(err);
      console.error(err.response);

      Sentry.withScope((scope) => {
        scope.setLevel('error');
        scope.setExtra(
          'message',
          `Captured server-side error code ${err.response?.status}`,
        );
        Sentry.captureException(err);
      });

      throw err;
    }
  };

  const requestErrorMessage = (error: AxiosError) => {
    const responseMessage = (error.response?.data as { message?: string })?.message;

    const errorMessage: string =
      responseMessage ||
      error.response?.status ||
      error.request ||
      error.message ||
      'Something went wrong';

    const config = {
      duration: 5,
      message: `Произошла ошибка при обращении к серверу: ${errorMessage}`,
    };

    return Toast.error(config);
  };

  const get = <T, P = GenericParams>(
    url: string,
    params?: P,
    hideMessage?: boolean,
    cancelToken?: CancelToken,
    options?: AxiosOptions,
  ) =>
    request<T>(
      'get',
      url,
      {
        params,
        paramsSerializer: (p) => queryString.stringify(p, { arrayFormat: 'comma' }),
        cancelToken,
        ...options,
      },
      hideMessage,
    );

  const post = <T, D = GenericData>(
    url: string,
    data?: D,
    hideMessage?: boolean,
    options?: AxiosOptions,
  ) => request<T>('post', url, { data, ...options }, hideMessage);

  const put = <T, D = GenericData>(
    url: string,
    data?: D,
    hideMessage?: boolean,
    options?: AxiosOptions,
  ) => request<T>('put', url, { data, ...options }, hideMessage);

  const patch = <T, D = GenericData>(
    url: string,
    data?: D,
    hideMessage?: boolean,
    options?: AxiosOptions,
  ) => request<T>('patch', url, { data, ...options }, hideMessage);

  const del = <T, D = GenericData>(url: string, data?: D, options?: AxiosOptions) =>
    request<T>('delete', url, { data, ...options });

  return {
    get,
    post,
    put,
    patch,
    del,
  };
};
