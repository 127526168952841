import React from 'react';
import { BackgroundColors } from 'shared/ui/types';
import * as Styled from './PageLayout.styled';

type PageLayoutProps = {
  children: React.ReactNode;
  backgroundColor?: BackgroundColors;
  withBreakpoints?: boolean;
};

export const PageLayout: React.FC<PageLayoutProps> = ({
  children,
  backgroundColor,
  withBreakpoints = true,
}) => (
  <Styled.Container $backgroundColor={backgroundColor}>
    <Styled.Body $withBreakpoints={withBreakpoints}>{children}</Styled.Body>
  </Styled.Container>
);
